import React, { useRef, useState } from "react";
import { UseFormSetValue, UseFormGetValues } from "react-hook-form";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ShippingAddress from "../../../components/Common/ShippingAddress";
import SelectBox from "../../../components/Common/Form/SelectBox";
import TextArea from "../../../components/Common/Form/TextArea";
import TextField from "../../../components/Common/Form/TextField";
import PaymentButton from "../../../components/Common/Buttons/PaymentButton";
import { HttpRequest } from "../../../utils/common";
import { EMAIL_MATCH_PATTERN } from "../../../utils/constants";
import { Questionnaire, ProjectInformation, CardInformation} from "../../../types/pages/EcWebsettlement/purchase_ec_return";
import { PurchaseEcReturnFormValues } from "../../../types/pages/EcReturn/Websettlement/purchase_ec_return";
import ConveniSelectionModal from "../../../components/EcReturn/Websettlement/ConveniModal";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";
import EmailConfirmationModal from "../../../components/EcWebsettlement/EmailConfirmationModal";
import BaseButton from "../../../components/Common/Buttons/BaseButton";
import VisaCardImg from "../../../../images/card_visa.jpg";
import MasterCardImg from "../../../../images/card_mastercard.jpg";
import JCBCardImg from "../../../../images/card_jcb.jpg";
import AmexCardImg from "../../../../images/card_amex.jpg";
import DiscoverCardImg from "../../../../images/card_discover.jpg";
import DinersCoardImg from "../../../../images/card_diners.jpg";

type Props = {
  questionnaire: Questionnaire,
  projectInformation: ProjectInformation,
  payJpPublicKey: string,
  cardInformation: () => CardInformation,
  trigger: any,
  register: any,
  control: any,
  setValue: UseFormSetValue<PurchaseEcReturnFormValues>,
  getValues: UseFormGetValues<PurchaseEcReturnFormValues>,
  watch: any,
  moveConfirmPage: () => void,
  moveSelectReturnPage: () => void,
  totalPriceByAmount: () => number,
  totalAmount: () => number,
  commission: number;
  currentEmail: string;
};

const EnterProfile = ({
  questionnaire,
  projectInformation,
  payJpPublicKey,
  cardInformation,
  trigger,
  register,
  control,
  setValue,
  getValues,
  watch,
  moveConfirmPage,
  moveSelectReturnPage,
  totalPriceByAmount,
  totalAmount,
  commission,
  currentEmail,
}: Props) => {
  const [hasCheckboxChanged, setHasCheckboxChanged] = useState<boolean>(false)
  const fulfilled = () => {
    return (
      watch("last_name") != "" &&
      watch("first_name") != "" &&
      watch("postal_code") != "" &&
      watch("full_address") != "" &&
      watch("phone_number") != ""
    );
  };

  const reasons = [
    "チームのファンだから",
    "選手の知り合いだから",
    "チーム関係者の知り合いだから",
    "地元のチーム・選手だから",
    "この種目・競技に思い入れがあるから",
    "支援募集ページの文章の内容に共感したから",
    "リターンが魅力的だから",
    "その他"
  ]

  const {
    brand,
    last4,
    exp_year,
    exp_month
  } = cardInformation()

  const isCardExipred = () => {
    if (!brand) return false;
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return (year > Number(exp_year)) || (year == Number(exp_year) && month > Number(exp_month))
  }

  const deliveryInfoRef = useRef(null)

  const [isConveniSelectionModalActive, setIsConveniSelectionModalActive] = useState<boolean>(false)
  const [isConveniWarningModalActive, setIsConveniWarningModalActive] = useState<boolean>(false)
  const [isEmailConfirmationModalActive, setIsEmailConfirmationModalActive] = useState<boolean>(false)
  const [isAddressEdited, setIsAddressEdited] = useState<boolean>(true) // 登録済み住所を利用する場合を考慮してデフォルトはTrue
  const [isAddressWarningModalActive, setIsAddressWarningModalActive] = useState<boolean>(false)
  const [currentOnSubmit, setCurrentOnSubmit] = useState(null)

  const handleAddressEdit = (isEdited: boolean) => {
    setIsAddressEdited(isEdited);
  };

  const checkDeliveryInfo = async () => {
    return await trigger(["last_name", "first_name", "postal_code", "full_address", "phone_number"])
  }

  const mailCheckAndMoveConfirmPage = async () => {
    setIsAddressWarningModalActive(false)
    if (currentEmail === watch("email")) {
      moveConfirmPage()
    } else {
      HttpRequest.put('/api/accounts/email', { unconfirmed_email: watch("email") })
        .then(() => {
          toggleEmailConfirmationModal()
        })
        .catch((error) => {
          console.log(error)
          window.confirm("このメールアドレスには変更できません。");
          return
        })
    }
  }

  const cardRenewalCallback = async (payjp_token: string) => {
    HttpRequest.put('/api/accounts/payment_object_id', { payjp_token })
      .then(async ({ data }) => {
        setValue('card_brand', data.brand)
        setValue('card_exp_year', data.exp_year)
        setValue('card_exp_month', data.exp_month)
        setValue('card_last4', data.last4)
        setValue('payment_method', 'credit_card')
        const result = await checkDeliveryInfo()
        if (result) {
          mailCheckAndMoveConfirmPage()
        } else {
          deliveryInfoRef.current.scrollIntoView({block: "center"})
        }
      })
      .catch((error) => {
        console.log(error)
        return
      })
  }

  const submitCreditCardPayment = async () => {
    const result = await checkDeliveryInfo()
    if (result) {
      setValue("payment_method", "credit_card")
      mailCheckAndMoveConfirmPage()
    } else {
      deliveryInfoRef.current.scrollIntoView({block: "center"})
    }
  }

  const submitBankPayemnt = async () => {
    const result = await checkDeliveryInfo()
    if (result) {
      setValue("payment_method", "bank")
      mailCheckAndMoveConfirmPage()
    } else {
      deliveryInfoRef.current.scrollIntoView({block: "center"})
    }
  }

  const openConviniSelectionModal = async() => {
    setIsConveniWarningModalActive(false)
    const result = await checkDeliveryInfo()
    if (result) {
      setValue("payment_method", "conveni")
      toggleConveniSelectionModal()
    } else {
      deliveryInfoRef.current.scrollIntoView({block: "center"})
    }
  }

  const toggleConveniSelectionModal = () => {
    setIsConveniSelectionModalActive(!isConveniSelectionModalActive)
  }

  const toggleConveniWarningModal = () => {
    setIsConveniWarningModalActive(!isConveniWarningModalActive)
  }

  const toggleEmailConfirmationModal = () => {
    setIsEmailConfirmationModalActive(!isEmailConfirmationModalActive)
  }
  
  const checkConveniWarning = () => {
    setIsAddressWarningModalActive(false)

    if (projectInformation.conveniWarning) {
      toggleConveniWarningModal()
    } else {
      openConviniSelectionModal()
    }
  }

  const toggleAddressWarningModal = () => {
    setIsAddressWarningModalActive(!isAddressWarningModalActive)
  }
  
  const checkAddressEdited = (type: "bank" | "registeredCreditCard" | "conveni") => {
    if (isAddressEdited) {
      switch (type) {
        case "bank":
          submitBankPayemnt()  
          break;
        case "registeredCreditCard":
          submitCreditCardPayment()
          break;
        case "conveni":
          checkConveniWarning()
          break;
      }
    } else {
      switch (type) {
        case "bank":
          setCurrentOnSubmit(() => submitBankPayemnt);
          toggleAddressWarningModal()
          break;
        case "registeredCreditCard":
          setCurrentOnSubmit(() => submitCreditCardPayment);
          toggleAddressWarningModal()
          break;
        case "conveni":
          setCurrentOnSubmit(() => checkConveniWarning);
          toggleAddressWarningModal()
          break;
      }
    }
  }

  const getBrandIconUrl = (brand) => {
    switch (brand) {
      case 'Visa':
        return VisaCardImg;
      case 'MasterCard':
        return MasterCardImg;
      case 'JCB':
        return JCBCardImg;
      case 'American Express':
        return AmexCardImg;
      case 'Diners Club':
        return DinersCoardImg;
      case 'Discover':
        return DiscoverCardImg;
      default:
        return null;
    }
  };

  return (
    <>
      <ConveniSelectionModal
        isActive={isConveniSelectionModalActive}
        toggleModal={toggleConveniSelectionModal}
        control={control}
        setValue={setValue}
      />
      <ConfirmationModal
        toggleModal={toggleConveniWarningModal}
        onSubmit={openConviniSelectionModal}
        isActive={isConveniWarningModalActive}
        confirmText="コンビニ決済情報の入力へ進む"
      >
        <div className="leading-[1.875]">
          <p className="mb-5">
            プロジェクト終了の期限まであとわずかです。
          </p>
          <p className="mb-5">
            お支払い期限はプロジェクト最終日となっております。
          </p>
          <p>
            コンビニ店頭払いのため、期限が過ぎる可能性がある場合は、クレジットカード決済を選択してください。
          </p>
        </div>
      </ConfirmationModal>
      <EmailConfirmationModal
        toggleModal={toggleEmailConfirmationModal}
        isActive={isEmailConfirmationModalActive}
        onSuccess={() => {
          moveConfirmPage()
          setIsEmailConfirmationModalActive(false)
        }}
      />
      <ConfirmationModal
        toggleModal={toggleAddressWarningModal}
        onSubmit={currentOnSubmit}
        isActive={isAddressWarningModalActive}
        confirmText="進む"
      >
        <div className="leading-[1.875]">
          <p className="mb-5">
            確実にリターン品をお届けするため、住所をご確認ください。
          </p>
          <p className="mb-1">
            〒{getValues("postal_code")}
          </p>
          <p className="mb-5">
            {getValues("full_address")}
          </p>
        </div>
      </ConfirmationModal>
      <div>
        <div className="mb-[30px] flex">
          <div className="w-[13%] border-[#1F2122] flex items-center justify-center border">
            1
          </div>
          <div className="h-[40px] bg-[#1F2122] w-[61%] relative flex items-center justify-center text-center text-white text-sm font-bold">
            <span className="text-[#1F2122] bg-[#768084] rounded-[50%] inline-block mr-1 w-5 h-5 text-center">
              2
            </span>
            お届け先と
            <br className="sm:hidden" />
            決済情報の入力
            <div className="right-triangle-thin right-[-7px] absolute"></div>
          </div>
          <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
            3
          </div>
          <div className="w-[13%] border-[#1F2122] flex items-center justify-center border-b border-r border-t">
            4
          </div>
        </div>
      </div>
      <div className="pb-[40px] border-[#D2DFE6] border-b">
        <p>合計の支援金額(税込)</p>
        <div className="text-[34px] mb-[10px] text-right font-bold">
          {totalAmount().toLocaleString()}
          <span className="text-lg">円</span>
        </div>
        <p className="text-[#768084] text-sm mb-1">内訳:</p>
        <div className="flex items-end flex-col">
          <div className="text-xs">
            リターンの購入金額: {totalPriceByAmount().toLocaleString()}円
          </div>
          { getValues("additional_amount") && Number(getValues("additional_amount")) > 0 && 
            <div className="flex justify-end text-xs">
              上乗せ支援:{getValues("additional_amount")}円
            </div>
          }
          <div className="text-xs">
            プラットフォーム利用料:{commission}円
          </div>
        </div>
      </div>
      <div className="py-[40px] border-[#D2DFE6] border-b" ref={deliveryInfoRef}>
        <p className="mb-[40px] font-bold" id="delivery-info">お届け先情報</p>
          <ShippingAddress
            control={control}
            watch={watch}
            setValue={setValue as any}
            getValues={getValues as any}
            onAddressEdit={handleAddressEdit}
          />
        <div className="flex items-center">
          <input
            type="checkbox"
            id="defaultAddress"
            {...register("save_social_profile")}
            className={`${hasCheckboxChanged ? "before:border-[#B1C2CC] before:border" : "before:bg-[#D3EAFF] before:border-[#006AC9] before:border-2"} before:top-[-4px] login-checkbox before:content-[''] before:absolute relative before:inline-block before:w-5 before:h-5 invisible before:visible`}
            onChange={() => setHasCheckboxChanged(true)}
          />
          <label
            htmlFor="defaultAddress"
            className="ml-[10px] text-sm font-medium"
          >
            この情報を標準のお届け先として保存する
          </label>
        </div>
      </div>
      <div className="py-10 border-[#D2DFE6] border-b">
        <div className="flex items-center mb-1">
          <label htmlFor="email" className="inline-block text-sm">
            通知先メールアドレス確認
          </label>
          <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
            必須
          </span>
        </div>
        <TextField
          name="email"
          placeholder="メールアドレスを入力してください"
          id="email"
          control={control}
          rules={{
            required: true,
            pattern: EMAIL_MATCH_PATTERN
          }}
          patternErrorMessage="メールアドレスが正しくありません"
        />
        <p className="text-[#768084] mt-1 text-xs">
          現在登録済みのメールアドレス(スポチュニティ登録時に入力されたもの又はSNSアカウントのもの)が表示されております。<br />
          正しいメールアドレスかご確認いただき、変更が必要な場合は変更後のメールアドレスを入力してください。<br />
          ※ メールアドレスを変更した場合、この後の画面にてワンタイムコードの入力が必要となります。
        </p>
      </div>
      <div className="border-[#D2DFE6] py-10 border-b">
        <p className="mb-10 font-bold" id="questionnaire">
          よろしければアンケートと一言コメントにお答えください（任意）
        </p>
        <p className="text-[#FF0000] mb-7 text-xs">
          ※本回答はプロジェクト画面に公開されます
        </p>
        { questionnaire.title && 
          <div className="mb-10">
            <div className="mb-1 font-raleway text-xs text-[#768084]">
              Question1:
            </div>
            <div>
              <p className="mb-1 font-medium">
                {questionnaire.title}
              </p>
              <SelectBox
                placeholder="選択してください"
                name="questionnaire_option_id"
                id="questionnaire_option_id"
                control={control}
                options={questionnaire.selectable_answers.map((answer) => (answer.sentence))}
                values={questionnaire.selectable_answers.map((answer) => (answer.id))}
              />
            </div>
          </div>
        }
        {questionnaire.free_text_title &&
          <div>
            <div className="mb-1 font-raleway text-xs text-[#768084]">
              Question2
            </div>
            <div className="mb-[30px]">
              <p className="mb-1 font-medium">
                {questionnaire.free_text_title}
              </p>
              <TextArea
                placeholder="入力してください"
                id="questionnaire_sentence"
                name="questionnaire_sentence"
                control={control}
                maxLengthErrorMessage="255文字以内で入力してください"
                rules={{
                  maxLength: 255,
                }}
              />
            </div>
          </div>
        }
        <div>
          <div className="mb-[30px]">
            <p className="font-medium">支援理由</p>
            <SelectBox
              placeholder="選択してください"
              name="destination_reason_text"
              id="destination_reason_text"
              control={control}
              options={reasons}
            />
          </div>
          <div>
            <p className="text-sm mb-1 font-medium">応援メッセージをお願いします</p>
            <TextArea
              placeholder="入力してください"
              name="article_message"
              id="article_message"
              control={control}
            />
          </div>
        </div>
      </div>
      <div className="py-10">
        <p className="mb-10 font-bold" id="payment-method">支払い方法の選択</p>
        <p className="text-[#FF0000] mb-10 bg-[#F4F6F8] p-5">
          決済完了後は、あとから変更・キャンセル(解約)はできません。ご注意ください。
        </p>
        { brand && (
          <div className="py-4 border-t border-solid border-grey-500">
            <div className="mb-6 h-[50px]">
              <BaseButton
                text="登録中のクレジットカードで支払う"
                onClick={() => checkAddressEdited("registeredCreditCard")}
                disabled={!fulfilled() || isCardExipred()}
              />
            </div>
            <div>
              <p className="mb-2 text-lg font-semibold">ご使用中のクレジットカード</p>
              <div className="flex items-center mb-2">
                <img 
                  src={getBrandIconUrl(brand)}
                  alt={`${brand}`}
                  className="mr-2"
                  style={{height: '30px', width: 'auto' }}
                />
              </div>
              <p className="mb-2">XXXX-XXXX-XXXX-{last4}</p>
              <p className="mb-2">
                {exp_month}/{exp_year}
              </p>
            </div>
            {
              isCardExipred() && (
                <p className="text-[#FF0000] text-xs mt-2 mb-3 font-semibold">
                  ※ クレジットカードの有効期限が切れております。「クレジットカードを変更する」よりカードを変更してください。
                </p>
              )
            }
            <p className="text-[#FF0000] text-xs">
              ※ 安全にご利用いただくために、クレジットカード情報は決済代行会社にて管理しており、第三者に開示されることは一切ありません。
            </p>
          </div>
        )}
        <div className="mb-[40px]">
          <div className="mb-[10px] h-[50px]">
            <PaymentButton
              disabled={() => {return !fulfilled()}}
              submitText="カードで支払う"
              text={ brand ? 'クレジットカードを変更する' : 'クレジットカードで支払う' }
              payJpPublicKey={payJpPublicKey}
              callback={cardRenewalCallback}
              isAddressEdited={isAddressEdited}
              postal_code = {getValues("postal_code")}
              full_address = {getValues("full_address")}
            />
          </div>
          <p className="text-[#FF0000] text-xs">
            { brand ? '※ カードを変更した場合は、継続支援で支援中のお支払いカードも次回の決済時から変更されます。' : '※ 安全にご利用いただくために、クレジットカード情報は決済代行会社にて管理しており、第三者に開示されることは一切ありません。' }
          </p>
        </div>
        <div className="mb-[40px]">
          <div className="mb-[10px] h-[50px]">
            <BaseButton
              text="コンビニで支払う"
              onClick={() => checkAddressEdited("conveni")}
              disabled={projectInformation.conveniUnavailable || !fulfilled()}
            />
          </div>
          { !projectInformation.conveniUnavailable ? (
            <p className="text-[#FF0000] text-xs">
              「コンビニで支払う」を選択されると後ほど、スポチュニティsupport@spportunity.co.jpから重要なメールが届きます。ボタンを押される前に予め受信設定をお願いいたします。
            </p>
          ) : (
            <p className="text-[#FF0000] text-xs">
              プロジェクト終了当日にコンビニ決済は選択できません。
            </p>
          )}
        </div>
        <div>
          <div className="mb-[10px] h-[50px]">
            <BaseButton
              text="銀行振込で支払う"
              onClick={() => checkAddressEdited("bank")}
              disabled={projectInformation.bankUnavailable || !fulfilled()}
            />
          </div>
          { !projectInformation.bankUnavailable ? (
            <p className="text-[#FF0000] text-xs">
              「銀行振込で支払う」を選択されると後ほど、スポチュニティsupport@spportunity.co.jpから重要なメールが届きます。ボタンを押される前に予め受信設定をお願いいたします。
            </p>
          ) : (
            <p className="text-[#FF0000] text-xs">
              プロジェクト終了7日以内に銀行振込決済は選択できません。
            </p>
          )}     
        </div>
      </div>
      <div className="text-center">
        <a
          onClick={moveSelectReturnPage}
          className="left-icon icon-stick-arrow-left text-sm font-medium"
        >
          支援内容の入力に戻る
        </a>
      </div>
    </>
  );
};

export default EnterProfile;
