import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BaseButton from "../../../../components/Common/Buttons/BaseButton";
import Radio from "../../../../components/Common/Form/Radio"
import TextField from "../../../../components/Common/Form/TextField";
import Counter from "../../../../components/EcReturn/Websettlement/Counter";
import { useRadio } from "../../../../hooks/Common/useRadio"
import { EcReturn, EcReturnSku, ProjectInformation } from "../../../../types/pages/EcWebsettlement/purchase_ec_return";
import { PurchaseEcReturnFormValues } from "../../../../types/pages/EcReturn/Websettlement/purchase_ec_return";
import { QUESTION_TYPE } from "../../../../utils/constants"
import { Control, UseFormGetValues, UseFormWatch } from "react-hook-form";
import LinkifyText from "../../../../components/Common/LinkifyText";

type Props = {
  ecReturn: EcReturn,
  ecReturnIndex: number,
  ecReturnLength: number,
  control: Control<PurchaseEcReturnFormValues, any>,
  setValue: any,
  getValues: UseFormGetValues<PurchaseEcReturnFormValues>,
  watch: UseFormWatch<PurchaseEcReturnFormValues>,
  priceByAmount: () => number[],
};
const EcReturnForm: React.FC<Props> = ({
  ecReturn,
  ecReturnIndex,
  ecReturnLength,
  control,
  setValue,
  getValues,
  watch,
  priceByAmount,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showMoreBtn, setShowMoreBtn] = useState<boolean>(false);
  const {
    isActive: isQuestionTypeSelectActive,
    onChange: onQuestionTypeSelectChange
  } = useRadio(false);
  const { isActive: isSkuActive, onChange: onSkuChange } = useRadio(false);
  const ecReturnContentRef = useRef(null);
  const [nowAnswers, setNowAnswers] = useState(getValues(`ec_returns_form.${ecReturnIndex}.answers`));

  const ecReturnSlider = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          arrows: true,
        },
      },
    ],
  };

  useEffect(() => {
    if (ecReturnContentRef.current.offsetHeight > 72) {
      setShowMoreBtn(true)
      ecReturnContentRef.current.classList.add("line-clamp-3")
    }
  }, [])

  const toggleShowMoreBtn = () => {
    setIsOpen(!isOpen)
    ecReturnContentRef.current.classList.toggle("line-clamp-3")
  }

  const checkedSku = (): EcReturnSku => {
    return ecReturn.ec_return_skus
      .filter((sku) => sku.id === getValues(`ec_returns_form.${ecReturnIndex}.checked_sku_id`))
      .shift();
  };

  return (
    <>
      <p className="pl-2 text-md font-bold bg-[#1F2122] text-white flex items-center justify-center">{ecReturnIndex+1}/{ecReturnLength} 個目</p>
      <div className="border-2 p-5 mb-[50px]">
        <div className="mb-5">
          <Slider {...ecReturnSlider}>
            {ecReturn.investment_image_urls.map((imageUrl, index) => (
              <div key={index}>
                <img src={imageUrl} alt={ecReturn.investment_title} />
              </div>
            ))}
          </Slider>
        </div>
        <p className="text-lg mb-5 font-bold">{ecReturn.name}</p>
        <div className="pb-[15px] border-[#D2DFE6] border-b">
          <div ref={ecReturnContentRef}>
            <LinkifyText text={ecReturn.content} />
          </div>
          <button
            className={
              `${showMoreBtn ? "block" : "hidden"}
              ${isOpen
                ? "w-full right-icon icon-arrow-top flex items-center justify-center mt-5 font-raleway after:ml-2 after:mt-1"
                : "w-full icon-arrow-under right-icon flex items-center justify-center mt-5 font-raleway after:ml-2 after:mb-1"}`
            }
            onClick={() => toggleShowMoreBtn()}
            type="button"
          >
            {isOpen ? "Close" : "More"}
          </button>
        </div>
        <div className="border-[#D2DFE6] py-5 border-b sm:flex sm:justify-between">
          <dl className="flex justify-between items-center sm:after:content-[''] sm:after:rotate-[30deg] sm:after:w-[1px] sm:after:h-full sm:after:bg-[#768084] sm:w-[30%]">
            <dt className="text-[#768084] text-sm icon-human left-icon sm:mr-[10px]">支援数</dt>
            <dd>{ecReturn.support_count}件</dd>
          </dl>
          <dl className="flex justify-between items-center sm:after:content-[''] sm:after:rotate-[30deg] sm:after:w-[1px] sm:after:h-full sm:after:bg-[#768084] sm:w-[30%]">
            <dt className="text-[#768084] text-sm icon-stock left-icon sm:mr-[10px]">在庫数</dt>
            <dd>{ecReturn.actual_remaining_number !== null ? `${ecReturn.actual_remaining_number}個` : "余裕あり"}</dd>
          </dl>
          <p className="text-[#FF0000] icon-gift-large left-icon">{ecReturn.is_subscription ? "月額課金中は継続してご提供" : `${ecReturn.schedule_text}に実施予定`}</p>
        </div>
        {ecReturn.ec_return_skus.length > 0 && (
          <div className="py-[40px] border-[#D2DFE6] border-b">
            <div className="mb-[40px] flex mb-2 items-center">
              <p className="font-bold">{ecReturn.sku_title}</p>
              <span className="bg-[#006AC9] text-xxs justify-center px-1 text-white ml-2">
                必須
              </span>
            </div>
            {ecReturn.ec_return_skus.map((ec_return_sku) => (
              <div
                key={ec_return_sku.id}
                className="first:mt-0 mt-6 sm:flex sm:justify-between sm:mt-4"
              >
                <Radio
                  checked={watch(`ec_returns_form.${ecReturnIndex}.checked_sku_id`) === ec_return_sku.id}
                  id={`${ec_return_sku.id}`}
                  value={ec_return_sku.sku_name}
                  label={ec_return_sku.sku_name}
                  name={`ec_returns_form.${ecReturnIndex}.checked_sku_id`}
                  control={control}
                  disabled={ec_return_sku.remaining_number <= 0}
                  onChange={(e) => {
                    onSkuChange(e);
                    setValue(`ec_returns_form.${ecReturnIndex}.checked_sku_id`, Number(e.target.id) || null);
                  }}
                  isActive={isSkuActive}
                />
                <div className="flex items-center maxsm:mt-2">
                  <span className="text-[#768084] icon-stock left-icon mr-[10px] text-sm">
                    在庫数
                  </span>
                  {ec_return_sku.remaining_number > 0 ? (
                    <span>{`${ec_return_sku.remaining_number}個`}</span>
                  ) : (
                    <span className="text-[#FF0000] font-bold">売り切れ</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {ecReturn.ec_return_questions.filter(question => question.question_type !== QUESTION_TYPE.NONE).length > 0 && (
          <div className="py-[40px] border-[#D2DFE6] border-b">
            <p className="mb-[40px] font-bold">リターンに関する質問</p>
            {ecReturn.ec_return_questions.filter(question => question.question_type !== QUESTION_TYPE.NONE).map((question, questionIndex) => (
              <div key={questionIndex} className="mb-[30px]">
                <div className="mb-5">
                  <div className="text-[#768084] mb-2 text-xs">質問{questionIndex+1}</div>
                  <p>{question.question}</p>
                </div>
                <div className="flex mb-2 items-center">
                  <label className="text-[#768084] text-xs" htmlFor="ec_questionnaire1">回答</label>
                  <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
                    必須
                  </span>
                </div>
                {question.question_type === QUESTION_TYPE.TEXT && (
                  <TextField
                    id={`question-${questionIndex}`}
                    type="text"
                    placeholder="回答を入力してください"
                    name={`ec_returns_form.${ecReturnIndex}.answers.${questionIndex}`}
                    control={control}
                    maxLengthErrorMessage="255文字以内で入力してください"
                    rules={{
                      required: true,
                      maxLength: 255,
                    }}
                    onChange={({ target: { value } }) =>
                      setValue(`answers.${questionIndex}`, value || "")
                    }
                  />
                )}
                {question.question_type === QUESTION_TYPE.SELECT && (
                  question.ec_return_question_question_answers.map((ans, ansIndex) => (
                    <div key={ansIndex} className="mt-4 first:mt-0">
                      <Radio
                        checked={watch(`ec_returns_form.${ecReturnIndex}.answers.${questionIndex}`) === ans.answer}
                        id={`return-${ecReturnIndex}-answer-${ansIndex}-of-${questionIndex}`}
                        value={ans.answer}
                        label={ans.answer}
                        name={`ec_returns_form.${ecReturnIndex}.answers.${questionIndex}`}
                        control={control}
                        onChange={(e) => {
                          onQuestionTypeSelectChange(e);
                          const copyAnswers = nowAnswers.slice(0, nowAnswers.length);
                          copyAnswers[questionIndex] = e.target.value; 
                          setNowAnswers(copyAnswers);
                          setValue(`answers.${questionIndex}`, e.target.value || "")
                        }}
                        isActive={isQuestionTypeSelectActive}
                      />
                    </div>
                    )
                  )
                )}
              </div>
            ))}
          </div>
        )}
        <div className="py-[40px]">
          <div className="flex items-center mb-5">
            <p className="font-bold" id="purchase-amount">リターンの購入金額(税込)</p>
            <span className="bg-[#006AC9] text-xxs flex items-center justify-center px-1 text-white ml-2">
              必須
            </span>
          </div>
          <div className="text-[34px] mb-5 text-right font-bold">
            {priceByAmount()[ecReturnIndex].toLocaleString()}
            <span className="ml-1 text-lg font-bold">円</span>
          </div>
          <div className="flex items-center justify-end">
            <div className="text-[#768084] mr-3 text-sm">口数</div>
            <Counter
              control={control}
              setValue={(value) => setValue(`ec_returns_form.${ecReturnIndex}.number`, value)}
              name={`ec_returns_form.${ecReturnIndex}.number`}
              id={`ec_returns_form.${ecReturnIndex}.number`}
              rules={{
                required: true,
                pattern: /^[1-9][0-9]{0,1}$|^100$/,
                max:
                  ecReturn.ec_return_skus?.length > 0
                    ? checkedSku().remaining_number
                    : ecReturn.remaining_number
              }}
              maxNumber={
                ecReturn.ec_return_skus?.length > 0
                  ? checkedSku().remaining_number
                  : ecReturn.remaining_number
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EcReturnForm;
